<template>
  <div class="login">
    <div class="permission">
      <img src="@/assets/no-access.png">
    </div>
  </div>
</template>

<script>
// 无权限访问页面
import { Auth } from '@/model'
export default {
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
    // this.getUserInfo()
  },
  methods: {
    async getUserInfo () {
      let res = await Auth.getUserinfo()
      if (!res['code']) {
        this.$cookie.delete('user')
        this.$store.dispatch('user', null)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  }
};
</script>

<style scoped>
.login {
  position: relative;
}
.permission {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -172px;
  margin-left: -329px;
  font-size: 30px;
  width: 658px;
  height: 344px;
}
</style>
